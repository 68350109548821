<template>
  <div>
    <b-card header="占空比计算" header-bg-variant="tmprimary" header-text-variant="white">
      <b-form inline>
        <b-input-group append="V">
          <b-input-group-prepend is-text>
            输入电压 U<sub>in</sub>=
          </b-input-group-prepend>
          <b-form-input id="input_uin" v-model="input_uin" type="text" />
        </b-input-group>
        &nbsp;
        <b-input-group append="V">
          <b-input-group-prepend is-text>
            输出电压 U<sub>out</sub>=
          </b-input-group-prepend>
          <b-form-input id="input_uout" v-model="input_uout" type="text" />
        </b-input-group>
      </b-form>
      <hr />
      <b-card header="Buck/隔离正激">
        <b-form inline>
          <b-input-group>
            <b-input-group-prepend is-text>
              匝数比-初级 N<sub>1</sub>=
            </b-input-group-prepend>
            <b-form-input id="input_g1_n1" v-model="input_g1_n1" type="text" />
          </b-input-group>
          &nbsp;
          <b-input-group>
            <b-input-group-prepend is-text>
              匝数比-次级 N<sub>2</sub>=
            </b-input-group-prepend>
            <b-form-input id="input_g1_n2" v-model="input_g1_n2" type="text" />
          </b-input-group>
          &nbsp; &nbsp;
          <label>若为非隔离Buck，匝数比填1:1</label>
        </b-form>
        <hr />
        <b-form inline>
          <b-input-group append="%">
            <b-input-group-prepend is-text>
              占空比 D=
            </b-input-group-prepend>
            <b-form-input id="output_g1_d" v-model="output_g1_d" type="text" readonly />
          </b-input-group>
        </b-form>
      </b-card>
      <br />
      <b-card header="Flyback">
        <b-form inline>
          <b-input-group>
            <b-input-group-prepend is-text>
              匝数比-初级 N<sub>1</sub>=
            </b-input-group-prepend>
            <b-form-input id="input_g2_n1" v-model="input_g2_n1" type="text" />
          </b-input-group>
          &nbsp;
          <b-input-group>
            <b-input-group-prepend is-text>
              匝数比-次级 N<sub>2</sub>=
            </b-input-group-prepend>
            <b-form-input id="input_g2_n2" v-model="input_g2_n2" type="text" />
          </b-input-group>
          &nbsp; &nbsp;
          <label>若为非隔离Flyback，匝数比填1:1，注意此时U<sub>out</sub>实际方向是相反的，但这里计算绝对值。</label>
        </b-form>
        <hr />
        <b-form inline>
          <b-input-group append="%">
            <b-input-group-prepend is-text>
              占空比 D=
            </b-input-group-prepend>
            <b-form-input id="output_g2_d" v-model="output_g2_d" type="text" readonly />
          </b-input-group>
        </b-form>
      </b-card>
      <br />
      <b-card header="Boost">
        <b-form inline>
          <b-input-group append="%">
            <b-input-group-prepend is-text>
              占空比 D=
            </b-input-group-prepend>
            <b-form-input id="output_g3_d" v-model="output_g3_d" type="text" readonly />
          </b-input-group>
        </b-form>
      </b-card>
    </b-card>
  </div>
</template>
<script>
const pi = 3.14159265358979;
export default {
  data() {
    return {
      input_uin: "0",
      input_uout: "0",
      input_g1_n1: "1",
      input_g1_n2: "1",
      input_g2_n1: "1",
      input_g2_n2: "1",
    };
  },
  computed: {
    output_g1_d(){
      var auin = Number(this.input_uin)
      var auout = Number(this.input_uout)
      var antr = Number(this.input_g1_n2) / Number(this.input_g1_n1)
      var ad = auout / auin / antr
      return ad * 100
    },
    output_g2_d(){
      var auin = Number(this.input_uin)
      var auout = Number(this.input_uout)
      var antr = Number(this.input_g2_n2) / Number(this.input_g2_n1)
      var a1md = auout / auin / antr
      var ad = a1md / (1 + a1md)
      return ad * 100
    },
    output_g3_d(){
      var auin = Number(this.input_uin)
      var auout = Number(this.input_uout)
      var adm = auin / auout
      var ad = 1 - adm
      return ad * 100
    },
  }
};
</script>